import CurentDataFactory from './curentDataFactory';
import { names } from '../components/constants/constants';

const validationSchema = (washtype: string[]) => {
  if (!washtype.length) {
    return names.needToChoose;
  }
  return '';
};

const curentWashTypes = new CurentDataFactory<string[]>([], validationSchema);

export default curentWashTypes;
