import CurentDataFactory from './curentDataFactory';
import { names } from '../components/constants/constants';
const validationSchema = (carType: string) => {
  if (!carType) {
    return names.needToChoose;
  }

  return '';
};

const curentCarType = new CurentDataFactory<string>('', validationSchema);

export default curentCarType;
