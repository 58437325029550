import { makeAutoObservable } from 'mobx';
import api from '../components/constants/apiPaths';
import dayjs from 'dayjs';
import { ICar } from '../types/types';

class Day {
  value: string = dayjs().format('YYYY-MM-DD');
  carsInDayFull: ICar[] = [];
  isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
    this.getCarFull(this.value);
  }

  public async getCarFull(date: string) {
    const res = await fetch(api.getCarsInDayFull(date));
    const out: ICar[] = await res.json();
    this.setCarsInDay(out);
  }

  public async setDay(value: string): Promise<void> {
    this.startLoading();
    this.setDefaultCarData();
    await this.getCarFull(value);
    this.setDayValue(value);
    this.finishLoading();
  }

  public async carInDayRefresh() {
    this.getCarFull(this.value);
  }

  private setCarsInDay(out: ICar[]) {
    this.carsInDayFull = out;
  }

  private setDayValue(value: string) {
    this.value = value;
  }

  private startLoading() {
    this.isLoading = true;
  }

  private finishLoading() {
    this.isLoading = false;
  }

  private setDefaultCarData() {
    this.carsInDayFull = [];
  }
}

const day = new Day();

export default day;
export { Day };
