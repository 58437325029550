import data from '../../../../../../../../../store/dataStore';
import api from '../../../../../../../../constants/apiPaths';
import { IDataWorkers, IWashTypes } from '../../../../../../../../../types/types';

interface IEditData {
  name: string;
  cost?: number;
  cartype?: number;
  id?: number;
}

const editHandler = (
  editHandler: 'editWorker' | 'editCarType' | 'editWashType',
  addNewHandler: 'addWorker' | 'addCarType' | 'addWashType',
  refresher: () => Promise<void>
) => {
  const out = async (data: IEditData, el?: IDataWorkers | IWashTypes) => {
    if (el) {
      data.id = el.id;
      await fetch(api[editHandler](), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(data),
      });
    } else {
      await fetch(api[addNewHandler](), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(data),
      });
    }

    await refresher();
  };

  return out;
};

export default editHandler;

const editWorkerHandler = editHandler('editWorker', 'addWorker', data.refreshWorkers.bind(data));
const editCarTypeHandler = editHandler('editCarType', 'addCarType', data.refreshCarTypes.bind(data));
const editWashTypeHandler = editHandler('editWashType', 'addWashType', data.refreshWashTypes.bind(data));

export { editWorkerHandler, editCarTypeHandler, editWashTypeHandler };
