import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Button, TextField, Divider } from '@mui/material';
import { names } from '../../../../../../../constants/constants';

import { IDataWorkers } from '../../../../../../../../types/types';
import { useTranslation } from 'react-i18next';

const errorText = names.tooFew;

interface IEditData {
  name: string;
  cost?: number;
  cartype?: number;
  id?: number;
}

interface IEditRowProp {
  el?: IDataWorkers;
  setEditIndex: React.Dispatch<React.SetStateAction<string>>;
  editor: (data: IEditData, el?: IDataWorkers) => Promise<void>;
}

const EditRow = ({ el, setEditIndex, editor }: IEditRowProp) => {
  const { t } = useTranslation();
  const [name, setName] = useState(el ? el.name : '');
  const [isError, setIsError] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (value.length > 2) {
      setIsError(false);
    }
    setName(value);
    // }
  };

  const saveHandler = () => {
    if (name.length <= 2) {
      setIsError(true);
      return;
    }

    editor({ name: name }, el);
    setEditIndex('');
  };

  return (
    <Grid key={'editRow'} alignItems='center' container item>
      <Grid item xs={4} display='flex' justifyContent='center'>
        <TextField
          size='small'
          error={isError}
          value={name}
          onChange={changeHandler}
          helperText={isError && t(errorText)}
        />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <Button size='small' disabled={isError} onClick={saveHandler}>
          <SaveIcon />
        </Button>
        <Divider orientation='vertical' />
        <Button size='small' onClick={() => setEditIndex('')}>
          <CloseIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditRow;
