import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ControlTab from './components/controlTab/ControlTab';
import WashtypeTab from './components/washTypeTab/WashtypeTab';
import { useTranslation } from 'react-i18next';
import { editWorkerHandler, editCarTypeHandler } from './components/controlTab/handlers/editHandler';
import { toggleActiveCarType, toggleActiveWorker } from './components/controlTab/handlers/toggleActive';
import { deleteCartype, deleteWorker } from './components/controlTab/handlers/deleteHandler';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: ITabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      {children}
    </Box>
  );
};

const ControlTable = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ marginBottom: '1rem', borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant='fullWidth'>
          <Tab label={t('workers')} id='tab-0' aria-controls='tabpanel-0' />
          <Tab label={t('cartypes')} id='tab-1' aria-controls='tabpanel-1' />
          <Tab label={t('washtypes')} id='tab-2' aria-controls='tabpanel-2' />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ControlTab name={'workers'} editor={editWorkerHandler} toggler={toggleActiveWorker} remover={deleteWorker} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ControlTab
          name={'cartypes'}
          editor={editCarTypeHandler}
          toggler={toggleActiveCarType}
          remover={deleteCartype}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <WashtypeTab />
      </CustomTabPanel>
    </Box>
  );
};

export default ControlTable;
