//

interface IApi {
  main: string;
  addCar: () => string;
  editCar: () => string;
  getAllWashTypes: () => string;
  getAllWorkers: () => string;
  getAllCarsTypes: () => string;
  getSettings: () => string;
  getWorkersAmount: () => string;
  getWorkerShare: () => string;
  setWorkersAmount: () => string;
  setWorkerShare: () => string;
  getCarsInHour: (date: string, hour: string) => string;
  getAllCarsInDay: (date: string) => string;
  getCarsInDayFull: (date: string) => string;
  deleteCar: (id: number) => string;
  getCarTypeFromId: (id: number) => string;
  getWorkersByCarId: (id: number) => string;
  getWashesByCarId: (id: number) => string;
  getSalaryFromDatePeriod: (from: string, to: string) => string;
  getTotalSummFromDatePeriod: (from: string, to: string) => string;
  toggleActive: (model: string, id: number) => string;
  editWorker: () => string;
  addWorker: () => string;
  editCarType: () => string;
  addCarType: () => string;
  deleteCarType: () => string;
  deleteWorker: () => string;
  addWashType: () => string;
  editWashType: () => string;
  deleteWashType: () => string;
}

// type IApi = keyof api;

const api: IApi = {
  // const api = {
  // main: 'http://localhost:7000/api',
  main: 'http://mashin.pro:7000/api',

  addCar() {
    return this.main + '/car/add';
  },

  editCar() {
    return this.main + '/car/edit';
  },

  deleteCar(id: number) {
    return this.main + `/car/delete?id=${id}`;
  },

  getAllCarsInDay(date: string) {
    return this.main + `/car/day?date=${date}`;
  },
  getCarsInDayFull(date: string) {
    return this.main + `/car/getCarsInDayV2?date=${date}`;
  },
  getCarsInHour(date: string, hour: string) {
    return this.main + `/car?date=${date}&hour=${hour}`;
  },
  getAllCarsTypes() {
    return this.main + `/cartype/getall`;
  },
  getCarTypeFromId(id: number) {
    return this.main + `/cartype/getone?id=${id}`;
  },
  getAllWashTypes() {
    return this.main + `/washtype/getall`;
  },
  getAllWorkers() {
    return this.main + `/worker/getall`;
  },
  getWorkersByCarId(id: number) {
    return this.main + `/car/worker?id=${id}`;
  },
  getWashesByCarId(id: number) {
    return this.main + `/car/washtype?id=${id}`;
  },

  getSalaryFromDatePeriod(from: string, to: string) {
    return this.main + `/money/salary?from=${from}&to=${to}`;
  },
  getTotalSummFromDatePeriod(from: string, to: string) {
    return this.main + `/money/total?from=${from}&to=${to}`;
  },
  getSettings() {
    return this.main + `/settings/get`;
  },
  getWorkersAmount() {
    return this.main + `/settings/getworkersamount`;
  },
  getWorkerShare() {
    return this.main + `/settings/getworkershare`;
  },
  setWorkersAmount() {
    return this.main + `/settings/setworkersamount`;
  },
  setWorkerShare() {
    return this.main + `/settings/setworkershare`;
  },
  toggleActive(model: string, id: number) {
    return this.main + `/${model}/toggleactive?id=${id}`;
  },
  editWorker() {
    return this.main + `/worker/edit`;
  },

  addWorker() {
    return this.main + `/worker/add`;
  },
  editCarType() {
    return this.main + `/cartype/edit`;
  },

  addCarType() {
    return this.main + `/cartype/add`;
  },

  deleteWorker() {
    return this.main + `/worker/delete`;
  },
  deleteCarType() {
    return this.main + `/cartype/delete`;
  },
  addWashType() {
    return this.main + `/washtype/add`;
  },
  editWashType() {
    return this.main + `/washtype/edit`;
  },
  deleteWashType() {
    return this.main + `/washtype/delete`;
  },
};

export default api;
