import React from 'react';
import { Grid, Divider } from '@mui/material';
import salaryData from '../../../../../store/salaryData';
import { observer } from 'mobx-react-lite';
import { names } from '../../../../constants/constants';
import { useTranslation } from 'react-i18next';

const SalaryTable = observer(() => {
  const { t } = useTranslation();
  if (salaryData.totalCars === 0) return <div></div>;
  const rows = salaryData.curent.map((data) => {
    return (
      <Grid key={data.workerName} container item columns={12}>
        <Grid item xs={4}>
          {data.workerName}
        </Grid>
        <Grid item xs={4}>
          {data.cars.length}
        </Grid>
        <Grid item xs={4}>
          {data.salary}
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <Divider children={t(names.salary)} sx={{ marginTop: '1rem' }} />
      <Grid container columns={12} columnSpacing={1} rowSpacing={2} sx={{ marginTop: '1rem', textAlign: 'center' }}>
        <Grid container item columns={12}>
          <Grid item xs={4} sx={{ borderRight: '1px solid Black' }}>
            {t(names.name)}
          </Grid>
          <Grid item xs={4}>
            {t(names.carsWashed)}
          </Grid>
          <Grid item xs={4} sx={{ borderLeft: '1px solid Black' }}>
            {t(names.salary)}
          </Grid>
        </Grid>
        {rows}
      </Grid>
    </>
  );
});

export default SalaryTable;
