import CurentDataFactory from './curentDataFactory';
import api from '../components/constants/apiPaths';
import { names } from '../components/constants/constants';

const workersFromDB = await (async () => {
  try {
    const json = await fetch(api.getWorkersAmount());
    const res = await json.json();
    return res;
  } catch (error) {
    console.error(error);
    return 0;
  }
})();

const validationSchema = (amount: string) => {
  if (!amount.length) {
    return names.needSome;
  } else if (!Number(amount)) {
    return names.numbersOnly;
  } else if (Number(amount) < 0 || Number(amount) > 4) {
    return names.incorectValue;
  }
  return '';
};

const curentWorkersAmount = new CurentDataFactory<string>(String(workersFromDB), validationSchema);

export default curentWorkersAmount;
