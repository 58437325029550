import api from '../../../../../constants/apiPaths';
import day from '../../../../../../store/dayStore';

const editCarHandler = async (carData: string) => {
  await fetch(api.editCar(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: carData,
  });

  day.carInDayRefresh();
};

export default editCarHandler;
