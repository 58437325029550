import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import CurentDataFactory from '../../../../../store/curentDataFactory';

interface ITextAreaProps {
  name: string;
  storeHandler: CurentDataFactory<string>;
}

const TextArea = observer(({ name, storeHandler }: ITextAreaProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    storeHandler.curent && storeHandler.validate();
  });

  const blurHandler = () => {
    storeHandler.validate();
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    storeHandler.set(value);
    storeHandler.isInvalid && storeHandler.validate();
  };

  //
  return (
    <TextField
      error={storeHandler.isInvalid}
      onBlur={blurHandler}
      onChange={changeHandler}
      placeholder={t(name)}
      variant='outlined'
      size='small'
      value={storeHandler.curent}
      helperText={storeHandler.isInvalid && t(storeHandler.errorText)}
    />
  );
});

export default TextArea;
