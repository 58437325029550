import React from 'react';
import { Grid, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';

import editID from '../../../../store/editIdStore';
import { ICar } from '../../../../types/types';
import RowWrapper from './RowWrapper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import showSubRowContent from './showSubRowContent';
import editSubRowContent from './editSubrowContent';

interface ISubRowProps {
  car: ICar | null;
  time: string;
  index: number;
}

const SubRow = observer(({ car, time, index }: ISubRowProps) => {
  const id = `${time}-${index}`;

  const content = id === editID.curent ? editSubRowContent(time, car) : showSubRowContent(car, time, id);

  if (car || id === editID.curent) {
    return (
      <RowWrapper
        carName={content.carName}
        carPlate={content.carPlate}
        carType={content.carType}
        carWashTypes={content.carWashTypes}
        carWorker={content.carWorker}
        washSum={content.washSum}
        buttonBlock={content.buttonBlock}
      />
    );
  }

  return (
    <Grid
      container
      sx={{
        border: '1px solid black',
      }}
    >
      <Button
        onClick={() => {
          editID.setEditId(id);
        }}
        children={<AddCircleIcon />}
      ></Button>
    </Grid>
  );
});

export default SubRow;
