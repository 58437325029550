import React from 'react';
import { Grid } from '@mui/material';
import SubRow from './subrow/SubRow';
import getCarsInHour from './handlers/getCarsInHour';
import { observer } from 'mobx-react-lite';

interface IRowProps {
  time: string;
}
const Row = observer(({ time }: IRowProps) => {
  const carsInHour = getCarsInHour(time);

  return (
    <Grid item container columns={12}>
      <Grid item container xs={1} alignItems='center' justifyContent='center' sx={{ border: '1px solid black' }}>
        {time}
      </Grid>
      <Grid item container xs={11}>
        {carsInHour.map((car, index) => {
          return <SubRow key={time + '_' + index} car={car} time={time} index={index} />;
        })}
      </Grid>
    </Grid>
  );
});

export default Row;
