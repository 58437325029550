import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import curentDataFactory from '../../../../../store/curentDataFactory';

import { IBaseType } from '../../../../../types/types';
import { Box, Chip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface ISelectProps {
  type: IBaseType[];
  name: string;
  storeHandler: curentDataFactory<string[]>;
  refreshSum?: () => void;
  disabled: boolean;
}

const SmartSelect = observer(({ type, name, storeHandler, refreshSum, disabled }: ISelectProps) => {
  const [color, setColor] = useState('grey');
  const { t } = useTranslation();
  const [variants, setVariants] = useState<string[]>(storeHandler.curent);

  useEffect(() => {
    setVariants(storeHandler.curent);
  }, [storeHandler.curent]);

  const handleChange = async (event: SelectChangeEvent<typeof variants>) => {
    const { value } = event.target;
    setColor('');
    setVariants(typeof value === 'string' ? value.split(',') : value);
    storeHandler.set(typeof value === 'string' ? value.split(',') : value);
    storeHandler.validate();
    refreshSum && refreshSum();
  };

  const handleClose = () => {
    storeHandler.validate();
  };

  return (
    <FormControl sx={{ width: '100%' }} size='small' error={storeHandler.isInvalid}>
      <Select
        disabled={disabled}
        displayEmpty
        multiple
        value={variants}
        onChange={handleChange}
        onClose={handleClose}
        size='small'
        sx={{ color: color }}
        renderValue={(variants) => {
          if (variants.length) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
                {variants.map((value) => {
                  const item = type.find((el: IBaseType) => el.id === Number(value));
                  if (!item) return <Chip key={value} label={value} />;
                  return <Chip key={item.name} label={item.name} />;
                })}
              </Box>
            );
          }
          return <Box>{name}</Box>;
        }}
      >
        <MenuItem disabled value=''>
          {name}
        </MenuItem>
        {type.map((el) => {
          return (
            <MenuItem key={el.name} value={String(el.id)}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
      {storeHandler.isInvalid && <FormHelperText>{t(storeHandler.errorText)}</FormHelperText>}
    </FormControl>
  );
});

export default SmartSelect;
