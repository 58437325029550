import CurentDataFactory from './curentDataFactory';
import { names } from '../components/constants/constants';

const validationSchema = (workers: string[]) => {
  if (!workers.length) {
    return names.needToChoose;
  }
  return '';
};

const curentWorkers = new CurentDataFactory<string[]>([], validationSchema);

export default curentWorkers;
