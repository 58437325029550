import React from 'react';

import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import editID from '../../../../../store/editIdStore';
import { ICar } from '../../../../../types/types';
import getCarProp from './handlers/getCarProp';
import curentCarName from '../../../../../store/curentCarName';
import curentCarPlate from '../../../../../store/curentCarPlate';
import curentCarType from '../../../../../store/curentCarType';
import curentWashSum from '../../../../../store/curentWashSum';
import curentWashTypes from '../../../../../store/curentWashTypes';
import curentWorkers from '../../../../../store/curentWorkers';

interface IEditButtonProp {
  car: ICar | null;
  id: string;
}

const EditButton = ({ car, id }: IEditButtonProp) => {
  const editHandler = () => {
    if (car !== null) {
      const defaultWashTypes = car.washTypes.reduce((acc: string[], cur) => {
        acc.push(String(cur.id));
        return acc;
      }, []);
      const defaultWorkers = car.worker.reduce((acc: string[], cur) => {
        acc.push(String(cur.id));
        return acc;
      }, []);

      curentCarName.set(String(getCarProp(car, 'name')));
      curentCarPlate.set(String(getCarProp(car, 'plate')));
      curentCarType.set(String(car ? getCarProp(car, 'carType') : ''));
      curentWashSum.set(String(getCarProp(car, 'summ')));

      curentWashTypes.set(defaultWashTypes);
      curentWorkers.set(defaultWorkers);
    }
  };

  return (
    <Button
      size='small'
      onClick={() => {
        editHandler();
        editID.setEditId(id);
      }}
    >
      <EditIcon />
    </Button>
  );
};

export default EditButton;
