import api from '../../../../constants/apiPaths';
import curentWorkersAmount from '../../../../../store/curentWorkersAmount';
type ControlFuncType = 'setWorkersAmount' | 'setWorkerShare';

const setSetting = (storageHandler: typeof curentWorkersAmount, settingName: ControlFuncType) => {
  return async () => {
    const amount = storageHandler.curent;

    const data = JSON.stringify({
      amount: Number(amount),
    });

    await fetch(api[settingName](), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: data,
    });
  };
};

export default setSetting;
