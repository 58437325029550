import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import day from '../../store/dayStore';

import Salary from './components/salary/Salary';
import Control from './components/control/Control';

import { times } from '../constants/constants';
import { CircularProgress, Grid, Box } from '@mui/material';
import Row from './components/Row';
import MainHeader from './components/MainHeader';

const Main = observer(() => {
  const progress = (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
      <CircularProgress />
    </Box>
  );
  const workTable = (
    <Grid container columns={12}>
      <MainHeader />
      {times.map((time, index) => {
        return <Row key={time + '_' + index} time={time} />;
      })}
    </Grid>
  );

  return (
    <Routes>
      <Route path='*' element={day.isLoading ? progress : workTable} />
      <Route path='salary' element={<Salary />} />
      <Route path='control' element={<Control />} />
    </Routes>
  );
});
export default Main;
