import React from 'react';

import curentCarName from '../../../../store/curentCarName';
import curentCarPlate from '../../../../store/curentCarPlate';
import curentWashSum from '../../../../store/curentWashSum';
import { ICar } from '../../../../types/types';
import { names } from '../../../constants/constants';
import CarTypeSelect from './components/CarTypeSelect';
import CloseButton from './components/CloseButton';
import SaveButton from './components/SaveButton';
import TextArea from './components/TextArea';
import WashTypeSelect from './components/WashTypeSelect';
import WorkerSelect from './components/WorkerSelect';

const editSubRowContent = (time: string, car: ICar | null) => {
  const carName = <TextArea name={names.name} storeHandler={curentCarName} />;
  const carPlate = <TextArea name={names.plate} storeHandler={curentCarPlate} />;

  const carType = <CarTypeSelect />;
  const carWashTypes = <WashTypeSelect />;

  const washSum = <TextArea name={names.cost} storeHandler={curentWashSum} />;

  const carWorker = <WorkerSelect />;

  const buttonBlock = (
    <>
      <SaveButton time={time} car={car} />
      <CloseButton />
    </>
  );

  return {
    carName,
    carPlate,
    carType,
    carWashTypes,
    washSum,
    carWorker,
    buttonBlock,
  };
};

export default editSubRowContent;
