import React from 'react';
import Calendar from './Calendar';
import { AppBar, Box, Container, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import settingsStatus from '../../store/settingsStatus';
import curentCarType from '../../store/curentCarType';
import editID from '../../store/editIdStore';
import curentWashSum from '../../store/curentWashSum';

const Header = () => {
  return (
    <AppBar position='sticky' sx={{ mb: '1em', flexGrow: 1, backgroundColor: '#fff', color: 'black' }}>
      <Container>
        <Toolbar sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Calendar />
          </Box>
          <IconButton
            size='large'
            edge='end'
            color='inherit'
            aria-label='menu'
            onClick={() => {
              editID.setDefault();
              curentCarType.setDefault();
              curentWashSum.setDefault();
              settingsStatus.open();
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
