import CurentDataFactory from './curentDataFactory';
import { names } from '../components/constants/constants';

const validationSchema = (value: string) => {
  if (value.length < 1 || value === '0') {
    return names.needSome;
  } else if (!Number(value) && value !== '0') {
    return names.numbersOnly;
  }

  return '';
};

const curentWashtypeCost = new CurentDataFactory<string>('', validationSchema);

export default curentWashtypeCost;
