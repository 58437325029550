import React from 'react';
import { Grid } from '@mui/material';

import { names } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

const sheetTheme = {
  border: '1px solid black',
  textAlign: 'center',
};

const MainHeader = () => {
  const { t } = useTranslation();
  return (
    <Grid container columns={12} sx={{ border: '1px solid black' }}>
      <Grid item xs={1} sx={sheetTheme}>
        {t(names.time)}
      </Grid>
      <Grid item xs={1} sx={sheetTheme}>
        {t(names.car)}
      </Grid>
      <Grid item xs={1} sx={sheetTheme}>
        {t(names.plate)}
      </Grid>
      <Grid item xs={2} sx={sheetTheme}>
        {t(names.cartype)}
      </Grid>
      <Grid item xs={2} sx={sheetTheme}>
        {t(names.washtypes)}
      </Grid>
      <Grid item xs={2} sx={sheetTheme}>
        {t(names.worker)}
      </Grid>
      <Grid item xs={1} sx={sheetTheme}>
        {t(names.cost)}
      </Grid>
    </Grid>
  );
};

export default MainHeader;
