/* eslint-disable import/no-anonymous-default-export */
import { makeAutoObservable } from 'mobx';

class editId {
  public curent: string = '';
  constructor() {
    makeAutoObservable(this);
  }

  public setEditId(id: string): void {
    this.curent = id;
  }

  public setDefault(): void {
    this.curent = '';
  }
}

const editID = new editId();

export default editID;
