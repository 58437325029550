import { action, makeObservable, observable } from 'mobx';

class SettingsStatus {
  public curent: boolean = false;
  constructor() {
    makeObservable(this, {
      curent: observable,
      open: action,
      close: action,
    });
  }

  public open(): void {
    this.curent = true;
  }

  public close(): void {
    this.curent = false;
  }
}

const settingsStatus = new SettingsStatus();

export default settingsStatus;
