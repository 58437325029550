// import { makeAutoObservable } from 'mobx';
import { makeAutoObservable } from 'mobx';
import api from '../components/constants/apiPaths';

import { IBaseType, IWashTypes, IDataWorkers, IDataCartype } from '../types/types';

class DataStore {
  cartypes: IDataWorkers[] = [];
  washtypes: string[] = [];
  workers: IDataWorkers[] = [];
  allWashTypes: IWashTypes[] = [];

  constructor() {
    this.setWorkers();
    this.setCartypes();
    this.setWashTypes();
    makeAutoObservable(this);
  }

  private async setWorkers() {
    const res = await fetch(api.getAllWorkers());
    const workers = await res.json();
    const out: IDataWorkers[] = workers.sort((a: IDataWorkers, b: IDataWorkers) => a.id - b.id);
    this.recWorkers(out);
  }

  private recWorkers(out: IDataWorkers[]) {
    this.workers = out;
  }

  public async refreshWorkers() {
    await this.setWorkers();
  }
  public async refreshCarTypes() {
    await this.setCartypes();
  }
  public async refreshWashTypes() {
    await this.setWashTypes();
  }

  private async setCartypes() {
    const res = await fetch(api.getAllCarsTypes());
    const carsTypes = await res.json();
    const out: IDataCartype[] = carsTypes.sort((a: IDataCartype, b: IDataCartype) => a.id - b.id);
    this.recCartypes(out);
  }

  private async recCartypes(cartypes: IDataCartype[]) {
    this.cartypes = cartypes;
  }

  private async setWashTypes() {
    const res = await fetch(api.getAllWashTypes());
    const washTypes: IWashTypes[] = await res.json();
    const out: IWashTypes[] = washTypes.sort((a: IDataWorkers, b: IDataWorkers) => a.id - b.id);
    this.recAllWashtypes(out);

    const washes: string[] = Array.from(new Set(washTypes.map((el: IBaseType): string => el.name)));

    this.recWashTypes(washes);
  }

  private recAllWashtypes(allWashTypes: IWashTypes[]) {
    this.allWashTypes = allWashTypes;
  }

  private recWashTypes(washes: string[]) {
    this.washtypes = washes;
  }
}

const data = new DataStore();

export default data;
