import React, { useEffect } from 'react';
import { Typography, Box, Divider, Grid } from '@mui/material';
import SmartInput from './components/SmartInput';
import SalaryTable from './components/SalaryTable';
import getSalaryFromPeriod from './handlers/getSalaryFromPeriod';
import salaryData from '../../../../store/salaryData';
import salaryDate from '../../../../store/salaryDate';
import { observer } from 'mobx-react-lite';
import getTotal from './handlers/getTotal';
import { names } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';

const Salary = observer(() => {
  const { t } = useTranslation();
  useEffect(() => {
    getSalaryFromPeriod();
    getTotal();
  }, []);

  useEffect(() => {
    getSalaryFromPeriod();
    getTotal();
  }, [salaryDate.from, salaryDate.to]);

  return (
    <Box>
      <Typography variant='h3' textAlign='center'>
        {t(names.salary)}
      </Typography>
      <Box sx={{ margin: '1rem 0' }}>
        {/* <Typography>{t(names.chooseDates)}</Typography> */}
        <Divider children={t(names.chooseDates)}></Divider>
        <Grid container columns={12} columnSpacing={2} sx={{ margin: '1rem 0' }}>
          <Grid item xs={2}></Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-evenly',
            }}
          >
            <Typography sx={{ marginRight: '1rem' }}>{t(names.from)} :</Typography>
            <SmartInput defaultValue={salaryDate.from} storeHandler={salaryDate.setFrom.bind(salaryDate)} />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              // justifyContent: 'space-evenly',
            }}
          >
            <Typography sx={{ marginRight: '1rem' }}>{t(names.to)} :</Typography>
            <SmartInput defaultValue={salaryDate.to} storeHandler={salaryDate.setTo.bind(salaryDate)} />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        {salaryDate.from > salaryDate.to && (
          <Box>
            <Typography>{t(names.errorDate)}</Typography>
          </Box>
        )}

        <Box>
          <Divider children={t(names.totalCash)}></Divider>
          <Typography sx={{ margin: '1rem 0' }} variant='h4' textAlign='center'>
            {salaryData.totalSum}
          </Typography>
        </Box>

        <Box>
          <Divider children={t(names.totalCars)}></Divider>
          <Typography sx={{ margin: '1rem 0' }} variant='h4' textAlign='center'>
            {salaryData.totalCars}
          </Typography>
        </Box>

        <Box>
          <SalaryTable />
        </Box>
      </Box>
    </Box>
  );
});

export default Salary;
