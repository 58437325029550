import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import curentWashtypeCost from '../../../../../../../../../store/curentWashtypeCost';
import curentWashtypeName from '../../../../../../../../../store/curentWashtypeName';

import TextArea from '../../../../../../subrow/components/TextArea';

import { IWashTypes } from '../../../../../../../../../types/types';

import { editWashTypeHandler } from '../../controlTab/handlers/editHandler';

interface IShowRowProp {
  carId: number;
  el?: IWashTypes;
  setEditIndex: React.Dispatch<React.SetStateAction<number>>;
}

const EditWash = observer(({ carId, el, setEditIndex }: IShowRowProp) => {
  if (el) {
    curentWashtypeName.set(el.name);
    curentWashtypeCost.set(String(el.cost));
  }

  const saveHandler = () => {
    curentWashtypeCost.validate();
    curentWashtypeName.validate();

    if (curentWashtypeCost.isInvalid && curentWashtypeName.isInvalid) return;

    editWashTypeHandler(
      { cartype: carId, name: curentWashtypeName.curent, cost: Number(curentWashtypeCost.curent) },
      el
    );

    closeHandler();
  };

  const closeHandler = () => {
    curentWashtypeCost.setDefault();
    curentWashtypeName.setDefault();

    setEditIndex(0);
  };

  return (
    <Grid key={'editWash'} container item sx={{ marginTop: '1rem' }}>
      <Grid item xs={3} textAlign='center'>
        <TextArea name={''} storeHandler={curentWashtypeName} />
      </Grid>
      <Grid item xs={3} textAlign='center'>
        <TextArea name={''} storeHandler={curentWashtypeCost} />
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          size='small'
          onClick={saveHandler}
          disabled={curentWashtypeName.isInvalid || curentWashtypeCost.isInvalid}
        >
          <SaveIcon />
        </Button>
        <Button size='small' onClick={closeHandler}>
          <CloseIcon />
        </Button>
      </Grid>
    </Grid>
  );
});

export default EditWash;
