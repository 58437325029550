import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import curentCarType from '../../../../../store/curentCarType';
import data from '../../../../../store/dataStore';
import { names } from '../../../../constants/constants';
import curentWashTypes from '../../../../../store/curentWashTypes';
import calcCurWashSum from './handlers/calcCurWashSum';
import { useTranslation } from 'react-i18next';

const CarTypeSelect = observer(() => {
  const [color, setColor] = useState('grey');
  const { t } = useTranslation();
  const type = data.cartypes.filter((el) => el.isActive);

  const handleClose = () => {
    curentCarType.validate();
  };

  const handleChange = (event: SelectChangeEvent<typeof curentCarType.curent>) => {
    const { value } = event.target;
    setColor('');
    curentCarType.set(value);
    curentCarType.validate();
    curentWashTypes.setDefault();
    calcCurWashSum();
  };

  return (
    <FormControl sx={{ width: '100%' }} size='small' error={curentCarType.isInvalid}>
      <Select
        displayEmpty
        value={curentCarType.curent}
        onChange={handleChange}
        onClose={handleClose}
        //
        sx={{ color: color }}
      >
        <MenuItem disabled value=''>
          {t(names.cartype)}
        </MenuItem>
        {type.map((el) => {
          return (
            <MenuItem key={el.name} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
      {curentCarType.isInvalid && <FormHelperText>{t(curentCarType.errorText)}</FormHelperText>}
    </FormControl>
  );
});
export default CarTypeSelect;
