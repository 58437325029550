import { ICar } from '../../../../types/types';
import day from '../../../../store/dayStore';
import curentWorkersAmount from '../../../../store/curentWorkersAmount';
import dayjs from 'dayjs';

const getCarsFromStore = (hour: string) => {
  const cars: ICar[] = day.carsInDayFull.filter((car) => car.hour === hour);
  return cars;
};

const carsListFiller = (arr: Array<ICar | null>, needAmount: number): Array<ICar | null> => {
  if (arr.length < needAmount) {
    arr.push(null);
    return carsListFiller(arr, needAmount);
  }
  return arr;
};

const getCarsInHour = (hour: string) => {
  const data = getCarsFromStore(hour).sort((a, b) => a.id - b.id);
  const workersAmount = day.value === dayjs().format('YYYY-MM-DD') ? Number(curentWorkersAmount.curent) : 1;
  return carsListFiller(data, workersAmount);
};

export default getCarsInHour;
