import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import CarTypeSelect from '../../../../../subrow/components/CarTypeSelect';
import curentCarType from '../../../../../../../../store/curentCarType';
import WashesTable from './components/WashesTable';
import { observer } from 'mobx-react-lite';

const WashtypeTab = observer(() => {
  useEffect(() => {
    curentCarType.setDefault();
  }, []);
  return (
    <Box>
      <CarTypeSelect />
      {curentCarType.curent && <WashesTable cartype={Number(curentCarType.curent)} />}
    </Box>
  );
});

export default WashtypeTab;
