import React from 'react';
import day from '../../store/dayStore';
import { observer } from 'mobx-react-lite';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import editID from '../../store/editIdStore';

const Calendar = observer(() => {
  const calendarHandler = (value: dayjs.Dayjs | null) => {
    const newDay = dayjs(value).format('YYYY-MM-DD');
    editID.setDefault();
    day.setDay(newDay);
  };

  return (
    <LocalizationProvider adapterLocale={'ru'} dateAdapter={AdapterDayjs}>
      <DatePicker
        slotProps={{ textField: { size: 'small', color: 'info' } }}
        format='DD.MM.YYYY'
        value={dayjs(day.value)}
        onChange={calendarHandler}
      />
    </LocalizationProvider>
  );
});

export default Calendar;
