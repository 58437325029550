import React from 'react';
import editID from '../../../../../store/editIdStore';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';

import addCarHandler from './handlers/addCarHandler';
import carDataBuilder from './handlers/carDataBuilder';
import { ICar } from '../../../../../types/types';
import editCarHandler from './handlers/editCarHandler';
import curentCarName from '../../../../../store/curentCarName';
import curentCarPlate from '../../../../../store/curentCarPlate';
import curentCarType from '../../../../../store/curentCarType';
import curentWashTypes from '../../../../../store/curentWashTypes';
import curentWorkers from '../../../../../store/curentWorkers';
import curentWashSum from '../../../../../store/curentWashSum';
import SaveIcon from '@mui/icons-material/Save';

interface ISaveButtonProps {
  time: string;
  car: ICar | null;
}

const SaveButton = observer(({ time, car }: ISaveButtonProps) => {
  const store = [curentCarName, curentCarPlate, curentCarType, curentWashTypes, curentWashSum];

  const validCheck = () => {
    store.forEach((el) => el.validate());
    const validStore = store.map((el) => el.isInvalid);

    if (validStore.find((el) => el === true)) {
      return true;
    } else {
      return false;
    }
  };

  const handleClick = () => {
    if (validCheck()) {
      return;
    }

    if (!car) {
      const carData = carDataBuilder(time);
      addCarHandler(carData);
    } else {
      const carData = carDataBuilder(time, car.id);
      editCarHandler(carData);
    }

    editID.setDefault();
    curentCarName.setDefault();
    curentCarPlate.setDefault();
    curentCarType.setDefault();
    curentWashSum.setDefault();
    curentWashTypes.setDefault();
    curentWorkers.setDefault();
  };

  return (
    <Button
      size='small'
      onClick={handleClick}
      onMouseEnter={() => console.log('enter')}
      children={<SaveIcon />}
    ></Button>
  );
});

export default SaveButton;
