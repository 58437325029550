import data from '../../../../../../../../../store/dataStore';
import api from '../../../../../../../../constants/apiPaths';

// const toggleActive = async (model: string, id: number) => {
//   const path = api.toggleActive(model, id);
//   await fetch(path);
// };

// export default toggleActive;

const toggleActive = (model: string, refresher: () => Promise<void>) => {
  return async (id: number) => {
    const path = api.toggleActive(model, id);
    await fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ id: id }),
    });
    await refresher();
  };
};

const toggleActiveWorker = toggleActive('worker', data.refreshWorkers.bind(data));
const toggleActiveCarType = toggleActive('carType', data.refreshCarTypes.bind(data));
const toggleActiveWashtype = toggleActive('washtype', data.refreshWashTypes.bind(data));

export { toggleActiveWorker, toggleActiveCarType, toggleActiveWashtype };
