import React from 'react';
import { Typography, Box, Button, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import setSetting from './handlers/setSetting';
import ControlTable from './components/controlTable/ControlTable';
import LanguageToggler from './components/LanguageToggler';
import { useTranslation } from 'react-i18next';
import { names } from '../../../constants/constants';
import SaveIcon from '@mui/icons-material/Save';

import curentWorkersAmount from '../../../../store/curentWorkersAmount';
import curentWorkerShare from '../../../../store/curentWorkerShare';
import TextArea from '../subrow/components/TextArea';

const setWorkerAmount = setSetting(curentWorkersAmount, 'setWorkersAmount');
const setShareAmount = setSetting(curentWorkerShare, 'setWorkerShare');

const Control = observer(() => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant='h3' textAlign='center'>
        {t(names.control)}
      </Typography>
      <Divider children={t(names.language)} textAlign='center' sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
      <LanguageToggler />
      <Divider children={t(names.workSettings)} textAlign='center' sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
      <Box display={'flex'} sx={{ justifyContent: 'space-around' }}>
        <Box>
          <Typography>{t(names.workersAmount)}</Typography>
          <TextArea name={names.workersAmount} storeHandler={curentWorkersAmount} />
          <Button onClick={setWorkerAmount} disabled={curentWorkersAmount.isInvalid}>
            <SaveIcon />
          </Button>
        </Box>
        <Box>
          <Typography>{t(names.workerShare)}</Typography>
          <TextArea name={names.workerShare} storeHandler={curentWorkerShare} />
          <Button onClick={setShareAmount} disabled={curentWorkerShare.isInvalid}>
            <SaveIcon />
          </Button>
        </Box>
      </Box>

      <Divider
        children={t(names.workAndTypeSettings)}
        textAlign='center'
        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
      />
      <ControlTable />
    </Box>
  );
});

export default Control;
