import React, { useState, useTransition } from 'react';

import data from '../../../../../../../../../store/dataStore';
import { Grid, Button } from '@mui/material';

import { observer } from 'mobx-react-lite';
import ShowWash from './ShowWash';
import EditWash from './EditWash';
import { IWashTypes } from '../../../../../../../../../types/types';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { names } from '../../../../../../../../constants/constants';

interface IWashTableProps {
  cartype: number;
}

const WashesTable = observer(({ cartype }: IWashTableProps) => {
  const [editIndex, setEditIndex] = useState<number>(0);
  const [addRow, setAddRow] = useState(0);
  const { t } = useTranslation();

  const curWashtypes: IWashTypes[] = data.allWashTypes.filter((type) => type.cartypeId === Number(cartype));

  const header = (
    <Grid container item>
      <Grid item xs={3} textAlign='center' sx={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>
        {t(names.name)}
      </Grid>
      <Grid item xs={3} textAlign='center' sx={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>
        {t(names.cost)}
      </Grid>
      <Grid item xs={3} textAlign='center' sx={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>
        {t(names.status)}
      </Grid>
      <Grid item xs={3} textAlign='center' sx={{ borderBottom: '1px solid black' }}>
        {t(names.control)}
      </Grid>
    </Grid>
  );

  const tableBody = curWashtypes.map((el, index) => {
    return el.id === editIndex ? (
      <EditWash key={index} carId={cartype} el={el} setEditIndex={setEditIndex} />
    ) : (
      <ShowWash key={index} el={el} setEditIndex={setEditIndex} />
    );
  });

  const handler = () => {
    setAddRow(1);
  };

  return (
    <Grid container sx={{ marginTop: '1.5rem' }}>
      {header}
      {tableBody}
      {addRow ? (
        <EditWash carId={cartype} setEditIndex={setAddRow} />
      ) : (
        <Grid item xs={12} textAlign='center' sx={{ marginTop: '1rem' }}>
          <Button
            onClick={handler}
            children={<AddIcon fontSize='large' />}
            sx={{
              width: '100%',
              backgroundColor: '#1976d2',
              color: '#fff',
              '&:hover': {
                color: '#1976d2',
                border: '1px solid #1976d2',
              },
            }}
          ></Button>
        </Grid>
      )}
    </Grid>
  );
});

export default WashesTable;
