import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      en: 'EN',
      ru: 'RU',
      language: 'Language',
      main: 'Main',
      salary: 'Salary',
      control: 'Control',
      workersAmount: 'Workers amount',
      workerShare: 'Workers share %',
      submit: 'Submit',
      plate: 'Plate',
      workers: 'Workers',
      worker: 'Worker',
      cartypes: 'Cartypes',
      cartype: 'Cartype',
      washtypes: 'Washtypes',
      name: 'Name',
      car: 'Car',
      status: 'Status',
      cost: 'Cost',
      time: 'Time',
      from: 'From',
      to: 'To',
      totalCash: 'Total cash',
      totalCars: 'Total cars',
      errorDate: 'Wrong date period',
      chooseDates: 'Choose dates',
      carsWashed: 'Cars washed',
      tooFew: 'Too few',
      tooLong: 'Too long',
      numbersOnly: 'Numbers only',
      needToChoose: 'Need to choose',
      needThreeNumber: 'Need 3 numbers',
      needSome: 'Need some',
      workSettings: 'Work',
      workAndTypeSettings: 'Worker and Types',
      incorectValue: 'Incorect value',
    },
  },
  ru: {
    translation: {
      en: 'АНГ',
      ru: 'РУС',
      language: 'Язык',
      main: 'Главная',
      salary: 'Зарплата',
      control: 'Настройки',
      workersAmount: 'Количество мойщиков',
      workerShare: 'Доля мойщика в %',
      submit: 'Сохранить',
      plate: 'Номер',
      workers: 'Мойщики',
      worker: 'Мойщик',
      cartypes: 'Типы авто',
      cartype: 'Тип авто',
      washtypes: 'Типы моек',
      name: 'Имя',
      car: 'Машина',
      status: 'Статус',
      cost: 'Цена',
      time: 'Время',
      from: 'С',
      to: 'По',
      totalCash: 'Общий вал',
      totalCars: 'Всего машин',
      errorDate: 'Неправильный период',
      chooseDates: 'Выберите даты',
      carsWashed: 'Помыто машин',
      tooFew: 'Мало символов',
      tooLong: 'Много символов',
      numbersOnly: 'Только числа',
      needToChoose: 'Нужно выбрать',
      needThreeNumber: 'Нужно 3 цифры',
      needSome: 'Нужно ввести',
      workSettings: 'Работа',
      workAndTypeSettings: 'Работники и Типы',
      incorectValue: 'Не коректное значение',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'ru',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
