import CurentDataFactory from './curentDataFactory';
import { names } from '../components/constants/constants';

const validationSchema = (name: string) => {
  if (name.length < 2) {
    return names.tooFew;
  } else if (name.length > 8) {
    return names.tooLong;
  }
  return '';
};

const curentCarName = new CurentDataFactory<string>('', validationSchema);

export default curentCarName;
