import { ICar, ICarProps } from '../../../../../../types/types';

const getCarProp = (car: ICar | null, prop: ICarProps) => {
  if (!car) {
    return '';
  }
  return car[prop];
};

export default getCarProp;
