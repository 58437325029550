import React from 'react';
import { observer } from 'mobx-react-lite';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

interface ISmartInputProp {
  defaultValue: string;
  storeHandler: (dateString: string) => void;
}

const SmartInput = observer(({ defaultValue, storeHandler }: ISmartInputProp) => {
  const calendarHandler = (value: dayjs.Dayjs | null) => {
    const newDay = dayjs(value).format('YYYY-MM-DD');
    storeHandler(newDay);
  };

  return (
    <LocalizationProvider adapterLocale={'ru'} dateAdapter={AdapterDayjs}>
      <DatePicker
        slotProps={{ textField: { size: 'small', color: 'info' } }}
        format='DD.MM.YYYY'
        value={dayjs(defaultValue)}
        onChange={calendarHandler}
      />
    </LocalizationProvider>
  );
});

export default SmartInput;
