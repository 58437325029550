import React from 'react';
import { Grid, Button, Box, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IWashTypes } from '../../../../../../../../../types/types';
import { toggleActiveWashtype } from '../../controlTab/handlers/toggleActive';

import { deleteWashtype } from '../../controlTab/handlers/deleteHandler';

interface IShowRowProp {
  el: IWashTypes;
  setEditIndex: React.Dispatch<React.SetStateAction<number>>;
}

const ShowWash = ({ el, setEditIndex }: IShowRowProp) => {
  return (
    <Grid key={el.id} container alignItems='center' item sx={{ marginTop: '1rem' }}>
      <Grid item xs={3} textAlign='center'>
        <Box>{el.name}</Box>
      </Grid>
      <Grid item xs={3} textAlign='center'>
        <Box>{el.cost}</Box>
      </Grid>
      <Grid item xs={3} textAlign='center'>
        <Box>
          <Checkbox
            checked={el.isActive}
            onChange={() => {
              toggleActiveWashtype(el.id);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button size='small' onClick={() => setEditIndex(el.id)}>
          <EditIcon />
        </Button>
        <Button size='small' onClick={() => deleteWashtype(el.id)}>
          <DeleteIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ShowWash;
