import React, { useState } from 'react';
import { Grid, Button, Divider, Box, Checkbox } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { IDataWorkers } from '../../../../../../../../types/types';

interface IShowRowProp {
  el: IDataWorkers;
  index: number;
  setEditIndex: React.Dispatch<React.SetStateAction<string>>;
  toggler: (id: number) => Promise<void>;
  remover: (id: number) => Promise<void>;
}

const ShowRow = ({ el, setEditIndex, toggler, remover }: IShowRowProp) => {
  const [checked, setChecked] = useState(el.isActive);

  const toggleChecked = () => {
    setChecked(!checked);
  };

  return (
    <Grid key={el.name} container item>
      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box>{el.name}</Box>
      </Grid>
      <Grid item xs={4} textAlign='center' alignItems='center'>
        <Box>
          <Checkbox
            checked={checked}
            onChange={() => {
              toggleChecked();
              toggler(el.id);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <Button size='small' onClick={() => setEditIndex(el.name)}>
          <EditIcon />
        </Button>
        <Divider orientation='vertical' sx={{ backGroundColor: 'black' }} />
        <Button size='small' onClick={() => remover(el.id)}>
          <DeleteIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ShowRow;
