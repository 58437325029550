import React from 'react';

import Main from './Main/Main';
import Header from './Header/Header';
import SettingsList from './Header/SettingsList';
import { Container } from '@mui/material';

const App = () => {
  return (
    <>
      <Header />
      <Container>
        <Main />
        <SettingsList />
      </Container>
    </>
  );
};

export default App;
