import { Box } from '@mui/material';
import { ICar } from '../../../../types/types';
import DeleteButton from './components/DeleteButton';
import EditButton from './components/EditButton';
import getCarProp from './components/handlers/getCarProp';
import getCarType from './components/handlers/getCartype';

const showSubRowContent = (car: ICar | null, time: string, id: string) => {
  const defaultName: string = String(getCarProp(car, 'name'));
  const defaultPlate: string = String(getCarProp(car, 'plate'));
  const defaultCarType = car ? getCarType(car.carType) : '';
  const defaultSum = String(getCarProp(car, 'summ'));

  let carName = <Box sx={{ textAlign: 'center' }}>{defaultName}</Box>;
  let carPlate = <Box sx={{ textAlign: 'center' }}>{defaultPlate}</Box>;
  let carType = <Box sx={{ textAlign: 'center' }}>{defaultCarType}</Box>;
  let washSum = <Box sx={{ textAlign: 'center' }}>{defaultSum}</Box>;

  let carWashTypes = (
    <Box sx={{ textAlign: 'center' }}>
      {car ? car.washTypes.map((washtype) => <Box key={time + '_washtype_' + washtype.id}>{washtype.name}</Box>) : []}
    </Box>
  );

  let carWorker = (
    <Box sx={{ textAlign: 'center' }}>
      {car ? car.worker.map((worker) => <Box key={time + '_worker_' + worker.id}>{worker.name}</Box>) : []}
    </Box>
  );

  let buttonBlock = (
    <>
      <EditButton car={car} id={id} />
      <DeleteButton car={car} />
    </>
  );

  return {
    carName,
    carPlate,
    carType,
    washSum,
    carWashTypes,
    carWorker,
    buttonBlock,
  };
};

export default showSubRowContent;
