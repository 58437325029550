import api from '../../../../../constants/apiPaths';
import day from '../../../../../../store/dayStore';

const addCarHandler = async (carData: string) => {
  await fetch(api.addCar(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: carData,
  });

  day.carInDayRefresh();
};

export default addCarHandler;
