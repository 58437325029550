import curentWashSum from '../../../../../../store/curentWashSum';
import curentWashTypes from '../../../../../../store/curentWashTypes';
import data from '../../../../../../store/dataStore';

const calcCurWashSum = () => {
  const cost = String(
    curentWashTypes.curent.reduce((acc, cur) => {
      if (data.allWashTypes.find((el) => el.id === Number(cur))) {
        const wash = data.allWashTypes.find((type) => type.id === Number(cur));
        return acc + (wash ? wash.cost : 0);
      }
      return 0;
    }, 0)
  );

  if (cost === curentWashSum.curent) return;
  curentWashSum.set(cost);
};

export default calcCurWashSum;
