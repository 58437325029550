import { action, makeObservable, observable } from 'mobx';

class CurentDataFactory<T> {
  private defaultValue: T;
  private validateFunc: (name: T) => string;
  public curent: T;
  public isInvalid: boolean = false;
  public errorText: string = '';

  constructor(defaultValue: T, validationFunc: (name: T) => string) {
    this.defaultValue = defaultValue;
    this.curent = defaultValue;
    this.validateFunc = validationFunc;
    makeObservable(this, {
      set: action,
      setDefault: action,
      validate: action,
      curent: observable,
      errorText: observable,
      isInvalid: observable,
    });
  }

  public set(value: T): void {
    this.curent = value;
  }

  public setDefault(): void {
    this.set(this.defaultValue);
    this.setValid(false);
  }

  public validate(): void {
    const res: string = this.validateFunc(this.curent);
    this.setErrorText(res);
    this.setValid(Boolean(res));
  }

  private setValid(value: boolean): void {
    this.isInvalid = value;
  }
  private setErrorText(value: string): void {
    this.errorText = value;
  }
}

export default CurentDataFactory;
