import api from '../../../../../../../../constants/apiPaths';
import data from '../../../../../../../../../store/dataStore';

const deleteFunc = (name: 'deleteWorker' | 'deleteCarType' | 'deleteWashType', refresher: () => Promise<void>) => {
  return async (id: number) => {
    await fetch(api[name](), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ id: id }),
    });
    await refresher();
  };
};

const deleteWorker = deleteFunc('deleteWorker', data.refreshWorkers.bind(data));
const deleteCartype = deleteFunc('deleteCarType', data.refreshCarTypes.bind(data));
const deleteWashtype = deleteFunc('deleteWashType', data.refreshWashTypes.bind(data));

export { deleteWorker, deleteCartype, deleteWashtype };
