import React from 'react';
import { useTranslation } from 'react-i18next';
import SmartSelect from './SmartSelect';
import { observer } from 'mobx-react-lite';

import curentCarType from '../../../../../store/curentCarType';
import data from '../../../../../store/dataStore';
import { names } from '../../../../constants/constants';
import curentWorkers from '../../../../../store/curentWorkers';

const WorkerSelect = observer(() => {
  const { t } = useTranslation();
  const name = names.worker;

  const activeWorkers = data.workers.filter((el) => el.isActive);

  return (
    <SmartSelect
      type={activeWorkers}
      name={t(name)}
      storeHandler={curentWorkers}
      disabled={curentCarType.curent ? false : true}
    />
  );
});

export default WorkerSelect;
