import React, { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { names } from '../../../../constants/constants';

const LanguageToggler = () => {
  const [language, setlanguage] = useState(localStorage.getItem('language') || names.ru);
  const { t, i18n } = useTranslation();

  const handleChange = (event: React.MouseEvent<HTMLElement>, language: string) => {
    setlanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ToggleButtonGroup
        size='small'
        color='primary'
        value={language}
        exclusive
        onChange={handleChange}
        aria-label='Platform'
      >
        <ToggleButton value='en'>{t('en')}</ToggleButton>
        <ToggleButton value='ru'>{t('ru')}</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default LanguageToggler;
