import React from 'react';

import { Grid } from '@mui/material';
const subrowTheme = {
  border: '1px solid black',
  paddingTop: '1rem',
  paddingBottom: '1rem',
};
const sheetTheme = {
  padding: '0 0.2rem',
};

interface IEditRowProps {
  [name: string]: JSX.Element;
}

const RowWrapper = ({ carName, carPlate, carType, carWashTypes, carWorker, washSum, buttonBlock }: IEditRowProps) => {
  return (
    <Grid container item alignItems='center' columns={11} sx={subrowTheme}>
      <Grid item xs={1} sx={sheetTheme}>
        {carName}
      </Grid>
      <Grid item xs={1} sx={sheetTheme}>
        {carPlate}
      </Grid>
      <Grid item xs={2} sx={sheetTheme}>
        {carType}
      </Grid>
      <Grid item xs={2} justifyContent='center' sx={sheetTheme}>
        {carWashTypes}
      </Grid>
      <Grid item xs={2} justifyContent='center' sx={sheetTheme}>
        {carWorker}
      </Grid>
      <Grid item xs={1} sx={sheetTheme}>
        {washSum}
      </Grid>
      <Grid
        item
        container
        wrap='nowrap'
        xs={2}
        sx={{
          ...sheetTheme,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {buttonBlock}
      </Grid>
    </Grid>
  );
};

export default RowWrapper;
