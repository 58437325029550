import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';

class SalaryDate {
  public from: string = '';
  public to: string = '';
  constructor() {
    makeAutoObservable(this);
    this.setFrom(dayjs().format('YYYY-MM-DD'));
    this.setTo(dayjs().format('YYYY-MM-DD'));
  }

  public setFrom(dateString: string): void {
    this.from = dateString;
  }

  public setTo(dateString: string): void {
    this.to = dateString;
  }

  setDefault(): void {
    this.setFrom('');
    this.setTo('');
  }
}

const salaryDate = new SalaryDate();

export default salaryDate;
