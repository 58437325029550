import React from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import settingsStatus from '../../store/settingsStatus';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { names } from '../constants/constants';

const pages = [
  { path: '/', name: names.main, icon: <HomeIcon /> },
  { path: '/salary', name: names.salary, icon: <AttachMoneyIcon /> },
  { path: '/control', name: names.control, icon: <SettingsIcon /> },
];

const SettingsList = observer(() => {
  const { t } = useTranslation();
  return (
    <Drawer anchor='right' open={settingsStatus.curent} onClose={() => settingsStatus.close()}>
      <Divider />
      <List sx={{ width: '15em' }}>
        {pages.map((el, index) => {
          return (
            <ListItem key={'link ' + index}>
              <ListItemButton component={Link} to={el.path}>
                <ListItemIcon>{el.icon}</ListItemIcon>
                <ListItemText primary={t(el.name)} />
              </ListItemButton>
              <Divider />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
});

export default SettingsList;
