import { makeAutoObservable } from 'mobx';
import { ICar } from '../types/types';

interface ISalaryData {
  workerName: string;
  workerId: number;
  salary: number;
  cars: ICar[];
}

class SalaryData {
  public curent: ISalaryData[] = [];
  public totalSum: number = 0;
  public totalCars: number = 0;
  constructor() {
    makeAutoObservable(this);
  }

  public setSalaryData(list: ISalaryData[]) {
    this.curent = list;
  }

  public setTotal(data: { totalSum: number; totalCars: number }) {
    this.totalSum = data.totalSum;
    this.totalCars = data.totalCars;
  }

  setDefault() {
    this.curent = [];
    this.totalSum = 0;
    this.totalCars = 0;
  }
}

const salaryData = new SalaryData();

export default salaryData;
