import React, { useState } from 'react';

import data from '../../../../../../../../store/dataStore';
import { Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { observer } from 'mobx-react-lite';
import ShowWorkerRow from './ShowRow';
import EditRow from './EditRow';
import { IDataWorkers } from '../../../../../../../../types/types';
import { useTranslation } from 'react-i18next';
import { names } from '../../../../../../../constants/constants';

interface IEditData {
  name: string;
  cost?: number;
  cartype?: number;
  id?: number;
}

interface IControlProps {
  name: 'cartypes' | 'workers';
  editor: (data: IEditData, el?: IDataWorkers) => Promise<void>;
  toggler: (id: number) => Promise<void>;
  remover: (id: number) => Promise<void>;
}

const ControlTab = observer(({ name, editor, toggler, remover }: IControlProps) => {
  const [editIndex, setEditIndex] = useState('');
  const [addRow, setAddRow] = useState('');
  const { t } = useTranslation();

  const header = (
    <Grid container item sx={{ marginBottom: '1rem' }}>
      <Grid item xs={4} textAlign='center' sx={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>
        {t(names.name)}
      </Grid>
      <Grid item xs={4} textAlign='center'>
        {t(names.status)}
      </Grid>
      <Grid item textAlign='center' xs={4} sx={{ borderLeft: '1px solid black', borderBottom: '1px solid black' }}>
        {t(names.control)}
      </Grid>
    </Grid>
  );

  const tableBody = data[name].map((el, index) => {
    return el.name === editIndex ? (
      <EditRow key={index} el={el} setEditIndex={setEditIndex} editor={editor} />
    ) : (
      <ShowWorkerRow
        key={index}
        el={el}
        index={index}
        setEditIndex={setEditIndex}
        toggler={toggler}
        remover={remover}
      />
    );
  });

  const handler = () => {
    setAddRow('0');
  };

  return (
    <Grid container>
      {header}

      {tableBody}
      {addRow ? (
        <EditRow setEditIndex={setAddRow} editor={editor} />
      ) : (
        <Grid item xs={12} textAlign='center' sx={{ marginTop: '1rem' }}>
          <Button
            onClick={handler}
            children={<AddIcon fontSize='large' />}
            sx={{
              width: '100%',
              backgroundColor: '#1976d2',
              color: '#fff',
              '&:hover': {
                color: '#1976d2',
                border: '1px solid #1976d2',
              },
            }}
          ></Button>
        </Grid>
      )}
    </Grid>
  );
});

export default ControlTab;
