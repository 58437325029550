import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import SmartSelect from './SmartSelect';

import calcCurWashSum from './handlers/calcCurWashSum';
import { names } from '../../../../constants/constants';
import curentCarType from '../../../../../store/curentCarType';
import data from '../../../../../store/dataStore';
import curentWashTypes from '../../../../../store/curentWashTypes';
import { IBaseType } from '../../../../../types/types';

const WashTypeSelect = observer(() => {
  const { t } = useTranslation();
  const name = names.washtypes;

  const washes = data.allWashTypes.reduce((acc: IBaseType[], washtype) => {
    if (washtype.cartypeId === Number(curentCarType.curent) && washtype.isActive) {
      acc.push({ id: washtype.id, name: washtype.name });
    }
    return acc;
  }, []);

  return (
    <SmartSelect
      type={washes}
      name={t(name)}
      storeHandler={curentWashTypes}
      refreshSum={calcCurWashSum}
      disabled={curentCarType.curent ? false : true}
    />
  );
});

export default WashTypeSelect;
