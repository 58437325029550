const times = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'];

const names = {
  time: 'time',
  car: 'car',
  plate: 'plate',
  worker: 'worker',
  en: 'en',
  ru: 'ru',
  language: 'language',
  main: 'main',
  salary: 'salary',
  control: 'control',
  workersAmount: 'workersAmount',
  workerShare: 'workerShare',
  submit: 'submit',
  workers: 'workers',
  cartypes: 'cartypes',
  cartype: 'cartype',
  washtypes: 'washtypes',
  name: 'name',
  status: 'status',
  cost: 'cost',
  from: 'from',
  to: 'to',
  totalCash: 'totalCash',
  totalCars: 'totalCars',
  errorDate: 'errorDate',
  chooseDates: 'chooseDates',
  carsWashed: 'carsWashed',
  tooFew: 'tooFew',
  tooLong: 'tooLong',
  numbersOnly: 'numbersOnly',
  needToChoose: 'needToChoose',
  needThreeNumber: 'needThreeNumber',
  needSome: 'needSome',
  incorectValue: 'incorectValue',
  workSettings: 'workSettings',
  workAndTypeSettings: 'workAndTypeSettings',
};

export { times, names };
