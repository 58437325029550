import api from '../../../../constants/apiPaths';
import salaryDate from '../../../../../store/salaryDate';
import salaryData from '../../../../../store/salaryData';

const getSalaryFromPeriod = async () => {
  const { from, to } = salaryDate;
  const path = api.getSalaryFromDatePeriod(from, to);

  const res = await fetch(path);
  const json = await res.json();

  salaryData.setSalaryData(json);
};

export default getSalaryFromPeriod;
