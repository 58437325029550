import React from 'react';
import editID from '../../../../../store/editIdStore';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import curentCarName from '../../../../../store/curentCarName';
import curentCarPlate from '../../../../../store/curentCarPlate';
import curentCarType from '../../../../../store/curentCarType';
import curentWashSum from '../../../../../store/curentWashSum';
import curentWashTypes from '../../../../../store/curentWashTypes';
import curentWorkers from '../../../../../store/curentWorkers';

const CloseButton = () => {
  const handleClick = () => {
    editID.setDefault();
    curentCarName.setDefault();
    curentCarPlate.setDefault();
    curentCarType.setDefault();
    curentWashSum.setDefault();
    curentWashTypes.setDefault();
    curentWorkers.setDefault();
  };

  return <Button onClick={handleClick} children={<CloseIcon />}></Button>;
};

export default CloseButton;
