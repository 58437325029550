import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import { Button } from '@mui/material';
import deleteCarHandler from './handlers/deleteCarHandler';
import { ICar } from '../../../../../types/types';

interface IDeleteButtonProps {
  car: ICar | null;
}

const DeleteButton = ({ car }: IDeleteButtonProps) => {
  return <Button size='small' onClick={() => deleteCarHandler(car ? car.id : 0)} children={<DeleteIcon />}></Button>;
};

export default DeleteButton;
