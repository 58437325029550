import curentCarName from '../../../../../../store/curentCarName';
import curentCarPlate from '../../../../../../store/curentCarPlate';
import curentCarType from '../../../../../../store/curentCarType';
import curentWashSum from '../../../../../../store/curentWashSum';
import curentWashTypes from '../../../../../../store/curentWashTypes';
import curentWorkers from '../../../../../../store/curentWorkers';
import day from '../../../../../../store/dayStore';

interface IDataToServer {
  id?: number;
  date: string;
  hour: string;
  name: string;
  plate: string;
  cartype: string;
  washIds: string;
  workersIds: string;
  summ: number;
}

const carDataBuilder = (time: string, id?: number) => {
  const data: IDataToServer = {
    date: day.value,
    hour: time,
    name: curentCarName.curent,
    plate: curentCarPlate.curent,
    cartype: curentCarType.curent,
    washIds: curentWashTypes.curent.slice().join(','),
    workersIds: curentWorkers.curent.slice().join(','),
    summ: Number(curentWashSum.curent),
  };

  if (id) {
    data.id = id;
  }

  return JSON.stringify(data);
};

export default carDataBuilder;
