import salaryData from '../../../../../store/salaryData';
import salaryDate from '../../../../../store/salaryDate';
import api from '../../../../constants/apiPaths';

const getTotal = async () => {
  const { from, to } = salaryDate;
  const path = api.getTotalSummFromDatePeriod(from, to);
  const res = await fetch(path);
  const json = await res.json();
  salaryData.setTotal(json);
};

export default getTotal;
