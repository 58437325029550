import CurentDataFactory from './curentDataFactory';
import { names } from '../components/constants/constants';

const validationSchema = (plate: string) => {
  if (plate.length !== 3) {
    return names.needThreeNumber;
  } else if (!Number(plate)) {
    return names.numbersOnly;
  }

  return '';
};

const curentCarPlate = new CurentDataFactory<string>('', validationSchema);

export default curentCarPlate;
